import React, { useState } from 'react';
import { List } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import faqsJson from '../../const/faq.json';
import _ from 'lodash';
import './index.scss';

const ListFaq = () => {

    const[ faqs, setFaqs ] = useState(faqsJson)

    const handleClick = (id) => {
        let index = _.findIndex(faqs,(faq) => faq.id === id);
        let temp = [...faqs];
        temp[index] = { ...temp[index], open: !temp[index].open };
        setFaqs([...temp]);
    }

    return (
        <List>
            {
                faqs.map((faq,index) => (
                    <>
                    <ListItem button onClick={()=>handleClick(faq.id)} key={index}>
                        {faq.open ? <ExpandLess className="icon-color"/> : <ExpandMore className="icon-color"/>}
                        <p>{faq.question}</p>
                        </ListItem>
                    <Collapse in={faq.open} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem button>
                                <p className="item-text">{faq.answer}</p>
                            </ListItem>
                        </List>
                    </Collapse>
                    </>
                ))
            }
        </List>
    )
}

export default ListFaq;