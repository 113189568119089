import { Container, Grid } from '@material-ui/core';
import React from 'react';
import Layout from '../../layout';
import preguntasImg from '../../images/faq.png'
import ListFaq from '../../components/List/';

const Faq = () => {

    return (
        <Layout footer>
            <Container className="mb-2 mt-2">
                <Grid container>
                    <Grid item md={6} xs={12}>
                        <img src={preguntasImg} width="100%" alt="faq"/>
                    </Grid>
                    <Grid item md={6} xs={12} style={{ margin:'auto' }}>
                        <ListFaq/>
                    </Grid>
                </Grid>
            </Container>
        </Layout>
    );
}

export default Faq;